.ant-form-item .ant-input-number+.ant-form-text {
  margin-left: 0.11rem;
}

.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}

.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 0.21rem;
  margin-bottom: 0;
}

.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0.32rem;
}

.ant-form-inline .ant-form-item>.ant-form-item-label,
.ant-form-inline .ant-form-item>.ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}

.ant-form-inline .ant-form-item>.ant-form-item-label {
  flex: none;
}

.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}

.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}

.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}

.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
  min-width: 0;
}

.ant-form-horizontal .ant-form-item-label[class$="-24"]+.ant-form-item-control,
.ant-form-horizontal .ant-form-item-label[class*="-24 "]+.ant-form-item-control {
  min-width: unset;
}

.ant-form-vertical .ant-form-item-row {
  flex-direction: column;
}

.ant-form-vertical .ant-form-item-label>label {
  height: auto;
}

.ant-form-vertical .ant-form-item .ant-form-item-control {
  width: 100%;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 0.11rem;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
}

.ant-form-vertical .ant-form-item-label>label,
.ant-col-24.ant-form-item-label>label,
.ant-col-xl-24.ant-form-item-label>label {
  margin: 0;
}

.ant-form-vertical .ant-form-item-label>label::after,
.ant-col-24.ant-form-item-label>label::after,
.ant-col-xl-24.ant-form-item-label>label::after {
  display: none;
}

.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 0.11rem;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }

  .ant-form-item .ant-form-item-label>label {
    margin: 0;
  }

  .ant-form-item .ant-form-item-label>label::after {
    display: none;
  }

  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }

  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }

  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 0.11rem;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }

  .ant-col-xs-24.ant-form-item-label>label {
    margin: 0;
  }

  .ant-col-xs-24.ant-form-item-label>label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 0.11rem;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }

  .ant-col-sm-24.ant-form-item-label>label {
    margin: 0;
  }

  .ant-col-sm-24.ant-form-item-label>label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 0.11rem;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }

  .ant-col-md-24.ant-form-item-label>label {
    margin: 0;
  }

  .ant-col-md-24.ant-form-item-label>label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 0.11rem;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }

  .ant-col-lg-24.ant-form-item-label>label {
    margin: 0;
  }

  .ant-col-lg-24.ant-form-item-label>label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}

@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 0.11rem;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }

  .ant-col-xl-24.ant-form-item-label>label {
    margin: 0;
  }

  .ant-col-xl-24.ant-form-item-label>label::after {
    display: none;
  }

  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}

.ant-form-item-explain-error {
  color: #ff4d4f;
}

.ant-form-item-explain-warning {
  color: #faad14;
}

.ant-form-item-has-feedback .ant-switch {
  margin: 0.03rem 0 0.05rem;
}

.ant-form-item-has-warning .ant-form-item-split {
  color: #faad14;
}

.ant-form-item-has-error .ant-form-item-split {
  color: #ff4d4f;
}

.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
}

.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 0.27rem;
  padding: 0;
  color: rgba(0,0,0,.45);
  font-size: 0.21rem;
  line-height: inherit;
  border: 0;
  border-bottom: 0.01rem solid #d9d9d9;
}

.ant-form label {
  font-size: 0.19rem;
}

.ant-form input[type=search] {
  box-sizing: border-box;
}

.ant-form input[type=radio],
.ant-form input[type=checkbox] {
  line-height: normal;
}

.ant-form input[type=file] {
  display: block;
}

.ant-form input[type=range] {
  display: block;
  width: 100%;
}

.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}

.ant-form input[type=file]:focus,
.ant-form input[type=radio]:focus,
.ant-form input[type=checkbox]:focus {
  outline: thin dotted;
  outline: 0.07rem auto -webkit-focus-ring-color;
  outline-offset: -0.03rem;
}

.ant-form output {
  display: block;
  padding-top: 0.2rem;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  line-height: 1.5715;
}

.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 0.11rem;
}

.ant-form-small .ant-form-item-label>label {
  height: 0.32rem;
}

.ant-form-small .ant-form-item-control-input {
  min-height: 0.32rem;
}

.ant-form-large .ant-form-item-label>label {
  height: 0.53rem;
}

.ant-form-large .ant-form-item-control-input {
  min-height: 0.53rem;
}

.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  margin-bottom: 0.32rem;
  vertical-align: top;
}

.ant-form-item-with-help {
  transition: none;
}

.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}

.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}

.ant-form-item-label-left {
  text-align: left;
}

.ant-form-item-label-wrap {
  overflow: unset;
  line-height: 1.3215em;
  white-space: unset;
}

.ant-form-item-label>label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 0.43rem;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
}

.ant-form-item-label>label>.anticon {
  font-size: 0.19rem;
  vertical-align: top;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 0.05rem;
  color: #ff4d4f;
  font-size: 0.19rem;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form-hide-required-mark .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-label>label .ant-form-item-optional {
  display: inline-block;
  margin-left: 0.05rem;
  color: rgba(0,0,0,.45);
}

.ant-form-hide-required-mark .ant-form-item-label>label .ant-form-item-optional {
  display: none;
}

.ant-form-item-label>label .ant-form-item-tooltip {
  color: rgba(0,0,0,.45);
  cursor: help;
  -ms-writing-mode: lr-tb;
  writing-mode: horizontal-tb;
  margin-inline-start: 0.05rem;
}

.ant-form-item-label>label::after {
  content: ":";
  position: relative;
  top: -0.01rem;
  margin: 0 0.11rem 0 0.03rem;
}

.ant-form-item-label>label.ant-form-item-no-colon::after {
  content: " ";
}

.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ant-form-item-control:first-child:not([class^=ant-col-]):not([class*=" ant-col-"]) {
  width: 100%;
}

.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 0.43rem;
}

.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}

.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  color: rgba(0,0,0,.45);
  font-size: 0.19rem;
  line-height: 1.5715;
  transition: color .3s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding-top: 0;
}

.ant-form-item-explain-connected {
  width: 100%;
}

.ant-form-item-extra {
  min-height: 0.32rem;
}

.ant-form-item-with-help .ant-form-item-explain {
  height: auto;
  opacity: 1;
}

.ant-form-item-feedback-icon {
  font-size: 0.19rem;
  text-align: center;
  visibility: visible;
  animation: zoomIn .3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}

.ant-form-item-feedback-icon-success {
  color: #52c41a;
}

.ant-form-item-feedback-icon-error {
  color: #ff4d4f;
}

.ant-form-item-feedback-icon-warning {
  color: #faad14;
}

.ant-form-item-feedback-icon-validating {
  color: #f9be00;
}

.ant-show-help {
  transition: opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-show-help-appear,
.ant-show-help-enter {
  opacity: 0;
}

.ant-show-help-appear-active,
.ant-show-help-enter-active {
  opacity: 1;
}

.ant-show-help-leave {
  opacity: 1;
}

.ant-show-help-leave-active {
  opacity: 0;
}

.ant-show-help-item {
  overflow: hidden;
  transition: height .3s cubic-bezier(0.645, 0.045, 0.355, 1),opacity .3s cubic-bezier(0.645, 0.045, 0.355, 1),transform .3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-show-help-item-appear,
.ant-show-help-item-enter {
  transform: translateY(-0.07rem);
  opacity: 0;
}

.ant-show-help-item-appear-active,
.ant-show-help-item-enter-active {
  transform: translateY(0);
  opacity: 1;
}

.ant-show-help-item-leave {
  transition: height .2s cubic-bezier(0.645, 0.045, 0.355, 1),opacity .2s cubic-bezier(0.645, 0.045, 0.355, 1),transform .2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-show-help-item-leave-active {
  transform: translateY(-0.07rem);
}

@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.ant-form-rtl {
  direction: rtl;
}

.ant-form-rtl .ant-form-item-label {
  text-align: left;
}

.ant-form-rtl .ant-form-item-label>label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 0.05rem;
}

.ant-form-rtl .ant-form-item-label>label::after {
  margin: 0 0.03rem 0 0.11rem;
}

.ant-form-rtl .ant-form-item-label>label .ant-form-item-optional {
  margin-right: 0.05rem;
  margin-left: 0;
}

.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 0.15rem;
  padding-left: 0.32rem;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 0.15rem;
  padding-left: 0.24rem;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-number-affix-wrapper .ant-input-number {
  padding: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 0.37rem;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 0.24rem;
}

.ant-form-rtl .ant-form-item-has-feedback>.ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback>.ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon)>.ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon)>.ant-select .ant-select-clear {
  right: auto;
  left: 0.43rem;
}

.ant-form-rtl .ant-form-item-has-feedback>.ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon)>.ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-number-group-addon)>.ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 0.56rem;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 0.25rem;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 0.43rem;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 0.15rem;
  padding-left: 0.39rem;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 0.15rem;
  padding-left: 0.39rem;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 0.09rem;
  padding-left: 0.34rem;
}

.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}

.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 0.21rem;
}