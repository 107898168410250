.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.85);
  font-size: 0.19rem;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  background: #fff;
  border-radius: 0.03rem;
}

.ant-card-rtl {
  direction: rtl;
}

.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow .3s,border-color .3s;
}

.ant-card-hoverable:hover {
  border-color: rgba(0,0,0,0);
  box-shadow: 0 0.01rem 0.03rem -0.03rem rgba(0,0,0,.16),0 0.04rem 0.08rem 0 rgba(0,0,0,.12),0 0.07rem 0.16rem 0.05rem rgba(0,0,0,.09);
}

.ant-card-bordered {
  border: 0.01rem solid #f0f0f0;
}

.ant-card-head {
  min-height: 0.64rem;
  margin-bottom: -0.01rem;
  padding: 0 0.32rem;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  font-size: 0.21rem;
  background: rgba(0,0,0,0);
  border-bottom: 0.01rem solid #f0f0f0;
  border-radius: 0.03rem 0.03rem 0 0;
}

.ant-card-head::before {
  display: table;
  content: "";
}

.ant-card-head::after {
  display: table;
  clear: both;
  content: "";
}

.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}

.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 0.21rem 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-card-head-title>.ant-typography,
.ant-card-head-title>.ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.ant-card-head .ant-tabs-top {
  clear: both;
  margin-bottom: -0.23rem;
  color: rgba(0,0,0,.85);
  font-weight: normal;
  font-size: 0.19rem;
}

.ant-card-head .ant-tabs-top-bar {
  border-bottom: 0.01rem solid #f0f0f0;
}

.ant-card-extra {
  margin-left: auto;
  padding: 0.21rem 0;
  color: rgba(0,0,0,.85);
  font-weight: normal;
  font-size: 0.19rem;
}

.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}

.ant-card-body {
  padding: 0.32rem;
}

.ant-card-body::before {
  display: table;
  content: "";
}

.ant-card-body::after {
  display: table;
  clear: both;
  content: "";
}

.ant-card-contain-grid .ant-card-body {
  display: flex;
  flex-wrap: wrap;
}

.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -0.01rem 0 0 -0.01rem;
  padding: 0;
}

.ant-card-grid {
  width: 33.33%;
  padding: 0.32rem;
  border: 0;
  border-radius: 0;
  box-shadow: 0.01rem 0 0 0 #f0f0f0,0 0.01rem 0 0 #f0f0f0,0.01rem 0.01rem 0 0 #f0f0f0,0.01rem 0 0 0 #f0f0f0 inset,0 0.01rem 0 0 #f0f0f0 inset;
  transition: all .3s;
}

.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 0.01rem 0.03rem -0.03rem rgba(0,0,0,.16),0 0.04rem 0.08rem 0 rgba(0,0,0,.12),0 0.07rem 0.16rem 0.05rem rgba(0,0,0,.09);
}

.ant-card-contain-tabs>.ant-card-head .ant-card-head-title {
  min-height: 0.43rem;
  padding-bottom: 0;
}

.ant-card-contain-tabs>.ant-card-head .ant-card-extra {
  padding-bottom: 0;
}

.ant-card-bordered .ant-card-cover {
  margin-top: -0.01rem;
  margin-right: -0.01rem;
  margin-left: -0.01rem;
}

.ant-card-cover>* {
  display: block;
  width: 100%;
}

.ant-card-cover img {
  border-radius: 0.03rem 0.03rem 0 0;
}

.ant-card-actions {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 0.01rem solid #f0f0f0;
}

.ant-card-actions::before {
  display: table;
  content: "";
}

.ant-card-actions::after {
  display: table;
  clear: both;
  content: "";
}

.ant-card-actions>li {
  margin: 0.16rem 0;
  color: rgba(0,0,0,.45);
  text-align: center;
}

.ant-card-actions>li>span {
  position: relative;
  display: block;
  min-width: 0.43rem;
  font-size: 0.19rem;
  line-height: 1.5715;
  cursor: pointer;
}

.ant-card-actions>li>span:hover {
  color: #f9be00;
  transition: color .3s;
}

.ant-card-actions>li>span a:not(.ant-btn),
.ant-card-actions>li>span>.anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0,0,0,.45);
  line-height: 0.29rem;
  transition: color .3s;
}

.ant-card-actions>li>span a:not(.ant-btn):hover,
.ant-card-actions>li>span>.anticon:hover {
  color: #f9be00;
}

.ant-card-actions>li>span>.anticon {
  font-size: 0.21rem;
  line-height: 0.29rem;
}

.ant-card-actions>li:not(:last-child) {
  border-right: 0.01rem solid #f0f0f0;
}

.ant-card-rtl .ant-card-actions>li:not(:last-child) {
  border-right: none;
  border-left: 0.01rem solid #f0f0f0;
}

.ant-card-type-inner .ant-card-head {
  padding: 0 0.32rem;
  background: #fafafa;
}

.ant-card-type-inner .ant-card-head-title {
  padding: 0.16rem 0;
  font-size: 0.19rem;
}

.ant-card-type-inner .ant-card-body {
  padding: 0.21rem 0.32rem;
}

.ant-card-type-inner .ant-card-extra {
  padding: 0.18rem 0;
}

.ant-card-meta {
  display: flex;
  margin: -0.05rem 0;
}

.ant-card-meta::before {
  display: table;
  content: "";
}

.ant-card-meta::after {
  display: table;
  clear: both;
  content: "";
}

.ant-card-meta-avatar {
  padding-right: 0.21rem;
}

.ant-card-rtl .ant-card-meta-avatar {
  padding-right: 0;
  padding-left: 0.21rem;
}

.ant-card-meta-detail {
  flex: 1 1;
  overflow: hidden;
}

.ant-card-meta-detail>div:not(:last-child) {
  margin-bottom: 0.11rem;
}

.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  font-size: 0.21rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-card-meta-description {
  color: rgba(0,0,0,.45);
}

.ant-card-loading {
  overflow: hidden;
}

.ant-card-loading .ant-card-body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-card-small>.ant-card-head {
  min-height: 0.48rem;
  padding: 0 0.16rem;
  font-size: 0.19rem;
}

.ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-head-title {
  padding: 0.11rem 0;
}

.ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-extra {
  padding: 0.11rem 0;
  font-size: 0.19rem;
}

.ant-card-small>.ant-card-body {
  padding: 0.16rem;
}