.ant-drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
}

.ant-drawer-inline {
  position: absolute;
}

.ant-drawer-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0,0,0,.45);
  pointer-events: auto;
}

.ant-drawer-content-wrapper {
  position: absolute;
  z-index: 1000;
  transition: all .3s;
}

.ant-drawer-content-wrapper-hidden {
  display: none;
}

.ant-drawer-left>.ant-drawer-content-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0.08rem 0 0.21rem -0.11rem rgba(0,0,0,.08),0.12rem 0 0.37rem 0 rgba(0,0,0,.05),0.16rem 0 0.64rem 0.21rem rgba(0,0,0,.03);
}

.ant-drawer-right>.ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: -0.08rem 0 0.21rem -0.11rem rgba(0,0,0,.08),-0.12rem 0 0.37rem 0 rgba(0,0,0,.05),-0.16rem 0 0.64rem 0.21rem rgba(0,0,0,.03);
}

.ant-drawer-top>.ant-drawer-content-wrapper {
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 0.08rem 0.21rem -0.11rem rgba(0,0,0,.08),0 0.12rem 0.37rem 0 rgba(0,0,0,.05),0 0.16rem 0.64rem 0.21rem rgba(0,0,0,.03);
}

.ant-drawer-bottom>.ant-drawer-content-wrapper {
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 -0.08rem 0.21rem -0.11rem rgba(0,0,0,.08),0 -0.12rem 0.37rem 0 rgba(0,0,0,.05),0 -0.16rem 0.64rem 0.21rem rgba(0,0,0,.03);
}

.ant-drawer-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  pointer-events: auto;
}

.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ant-drawer-header {
  display: flex;
  flex: 0 1;
  align-items: center;
  padding: 0.21rem 0.32rem;
  font-size: 0.21rem;
  line-height: 0.29rem;
  border-bottom: 0.01rem solid #f0f0f0;
}

.ant-drawer-header-title {
  display: flex;
  flex: 1 1;
  align-items: center;
  min-width: 0;
  min-height: 0;
}

.ant-drawer-extra {
  flex: none;
}

.ant-drawer-close {
  display: inline-block;
  margin-right: 0.16rem;
  color: rgba(0,0,0,.45);
  font-weight: 700;
  font-size: 0.21rem;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: rgba(0,0,0,0);
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color .3s;
  text-rendering: auto;
}

.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0,0,0,.75);
  text-decoration: none;
}

.ant-drawer-title {
  flex: 1 1;
  margin: 0;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  font-size: 0.21rem;
  line-height: 0.29rem;
}

.ant-drawer-body {
  flex: 1 1;
  min-width: 0;
  min-height: 0;
  padding: 0.32rem;
  overflow: auto;
}

.ant-drawer-footer {
  flex-shrink: 0;
  padding: 0.13rem 0.21rem;
  border-top: 0.01rem solid #f0f0f0;
}

.panel-motion-enter-start,
.panel-motion-appear-start,
.panel-motion-leave-start {
  transition: none;
}

.panel-motion-enter-active,
.panel-motion-appear-active,
.panel-motion-leave-active {
  transition: all .3s;
}

.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active,
.ant-drawer-mask-motion-leave-active {
  transition: all .3s;
}

.ant-drawer-mask-motion-enter,
.ant-drawer-mask-motion-appear {
  opacity: 0;
}

.ant-drawer-mask-motion-enter-active,
.ant-drawer-mask-motion-appear-active {
  opacity: 1;
}

.ant-drawer-mask-motion-leave {
  opacity: 1;
}

.ant-drawer-mask-motion-leave-active {
  opacity: 0;
}

.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start,
.ant-drawer-panel-motion-left-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active,
.ant-drawer-panel-motion-left-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-left-enter-start,
.ant-drawer-panel-motion-left-appear-start {
  transform: translateX(-100%) !important;
}

.ant-drawer-panel-motion-left-enter-active,
.ant-drawer-panel-motion-left-appear-active {
  transform: translateX(0);
}

.ant-drawer-panel-motion-left-leave {
  transform: translateX(0);
}

.ant-drawer-panel-motion-left-leave-active {
  transform: translateX(-100%);
}

.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start,
.ant-drawer-panel-motion-right-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active,
.ant-drawer-panel-motion-right-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-right-enter-start,
.ant-drawer-panel-motion-right-appear-start {
  transform: translateX(100%) !important;
}

.ant-drawer-panel-motion-right-enter-active,
.ant-drawer-panel-motion-right-appear-active {
  transform: translateX(0);
}

.ant-drawer-panel-motion-right-leave {
  transform: translateX(0);
}

.ant-drawer-panel-motion-right-leave-active {
  transform: translateX(100%);
}

.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start,
.ant-drawer-panel-motion-top-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active,
.ant-drawer-panel-motion-top-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-top-enter-start,
.ant-drawer-panel-motion-top-appear-start {
  transform: translateY(-100%) !important;
}

.ant-drawer-panel-motion-top-enter-active,
.ant-drawer-panel-motion-top-appear-active {
  transform: translateY(0);
}

.ant-drawer-panel-motion-top-leave {
  transform: translateY(0);
}

.ant-drawer-panel-motion-top-leave-active {
  transform: translateY(-100%);
}

.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start,
.ant-drawer-panel-motion-bottom-leave-start {
  transition: none;
}

.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active,
.ant-drawer-panel-motion-bottom-leave-active {
  transition: all .3s;
}

.ant-drawer-panel-motion-bottom-enter-start,
.ant-drawer-panel-motion-bottom-appear-start {
  transform: translateY(100%) !important;
}

.ant-drawer-panel-motion-bottom-enter-active,
.ant-drawer-panel-motion-bottom-appear-active {
  transform: translateY(0);
}

.ant-drawer-panel-motion-bottom-leave {
  transform: translateY(0);
}

.ant-drawer-panel-motion-bottom-leave-active {
  transform: translateY(100%);
}

.ant-drawer-rtl {
  direction: rtl;
}

.ant-drawer-rtl .ant-drawer-close {
  margin-right: 0;
  margin-left: 0.16rem;
}